<template>
  <g-card :page-title="pageTitle" :page-icon="pageIcon" :back-button="backButton">
    <g-overlay :is-relay="isRelay"/>
    <v-row>
      <v-col cols="12">
        <v-data-table
            :footer-props="{'items-per-page-options': limitList}"
            :headers="headers"
            :items="data"
            :options.sync="pagination"
            :server-items-length="total"
            :sort-by="sort"
            :sort-desc="descending"
            class="elevation-1 mt-0"
            :item-key="itemKey"
        >
          <template v-slot:top>
            <v-row class="mt-2 ma-2">
              <g-date-selector2
                  v-if="showComponent.isDate"
                  @onChangeDateHandler="onChangeDateHandler"
                  class="ma-2"
                  style="max-width: 250px"
              />
              <g-p-auto-complete
                  v-if="showComponent.isUser"
                  :dense="true"
                  :outlined="true"
                  :status=false
                  :validation="false"
                  apiPath="get-admins"
                  search-label="Select a User"
                  @clearSearchResult="userHandler"
                  @selectSearchResult="userHandler"
                  class="ma-2 ml-6"
                  style="max-width: 280px"
              />
              <g-filter-autocomplete
                  v-if="showComponent.isStatus"
                  placeholder="Select a Status"
                  :search-items="statusArr"
                  @onChangeHandler="onChangeFilterHandler($event, 'paymentStatus')"
                  class="ma-2"
                  style="max-width: 280px"
              />
              <g-p-auto-complete
                  v-if="showComponent.isMethod"
                  :dense="true"
                  :outlined="true"
                  :status=false
                  :validation="false"
                  apiPath="methods"
                  search-label="Select Payment Method"
                  @clearSearchResult="methodHandler"
                  @selectSearchResult="methodHandler"
                  class="ma-2 ml-6"
                  style="max-width: 280px"
              />
              <g-filter-autocomplete
                  placeholder="Select a Client Type"
                  v-if="showComponent.isClientType"
                  :search-items="clientTypes"
                  @onChangeHandler="onChangeFilterHandler($event, 'param1')"
                  class="ma-2"
                  style="max-width: 280px"
              />
              <g-p-auto-complete
                  v-if="showComponent.isEmail"
                  :dense="true"
                  :outlined="true"
                  :status=true
                  :validation="false"
                  apiPath="sales-counter/get-all-email"
                  item-text="email"
                  item-value="email"
                  search-label="Select a Email"
                  @clearSearchResult="handleClearSearchResult"
                  @selectSearchResult="onSelections"
                  class="ma-2"
                  style="max-width: 390px"
              />
              <g-p-auto-complete
                  v-if="showComponent.isSite"
                  :dense="true"
                  :outlined="true"
                  :status=true
                  :validation="false"
                  apiPath="sites"
                  item-text="site_url"
                  search-label="Select a Site"
                  sort-by="site_url"
                  @clearSearchResult="handleClearSiteSearchResult"
                  @selectSearchResult="onSelectionsSite"
                  class="ma-2"
                  style="max-width: 280px"
              />
              <v-select
                  class="mt-2 ml-2"
                  style="max-width: 390px"
                  v-if="isRandomPayment"
                  v-model="param1"
                  :items="organizations"
                  label="Select Organization"
                  outlined
                  dense
                  clearable
                  @change="loadData(true)"
              />
              <v-tooltip top v-if="showComponent.isPDF && (startDate || endDate)">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="mt-2 ml-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="downloadStatement()"
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon dark>
                      mdi-arrow-down-bold-circle-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Download Report as PDF</span>
              </v-tooltip>
              <v-tooltip top v-if="showComponent.isStaffPDF && (startDate || endDate)">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="mt-2 ml-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="downloadStaff()"
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon dark>
                      mdi-arrow-down-bold-circle-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Download Staff Report as PDF</span>
              </v-tooltip>
              <v-tooltip top v-if="showComponent.isSalesListPDF && (startDate || endDate)">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="mt-2 ml-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="downloadSalesSites()"
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon dark>
                      mdi-arrow-down-bold-circle-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Download Sales Link</span>
              </v-tooltip>
            </v-row>
            <v-row class="mt-2 ma-2" v-if="showComponent.isBulk">
              <v-col cols="6" md="6">
                <v-textarea
                   label="Site Links(MAX allowed 50)"
                   outlined
                   dense
                   @change="siteLinksHandler($event)"
                />
              </v-col>
              <v-col cols="6" md="6">
                <v-autocomplete
                    v-model="siteIds"
                    :items="sites"
                    label="Bulk Sites(MAX allowed 50)"
                    chips
                    deletable-chips
                    multiple
                    outlined
                    item-value="id"
                    item-text="site_url"
                    :search-input.sync="search"
                    @change="onSelectionsBulkSites"
                />
              </v-col>
            </v-row>
            <v-row v-if="showComponent.isSite" class="mt-2 ma-2">
              <g-p-auto-complete
                  :dense="true"
                  :outlined="true"
                  :status=true
                  :validation="false"
                  apiPath="site-owners"
                  item-text="name"
                  search-label="Site Owner"
                  sort-by="name"
                  @clearSearchResult="handleClearSiteOwnerSearchResult"
                  @selectSearchResult="onSelectionsSiteOwner"
                  class="ma-2"
              />
              <v-text-field
                  v-model="totalPayable"
                  label="Total Payable"
                  prepend-inner-icon="mdi-currency-usd"
                  outlined
                  required
                  dense
                  readonly
                  class="ma-2 right-align-text"
              />
              <v-text-field
                  v-model="prOfPayable"
                  label="Percentage(%) of Payable"
                  prepend-inner-icon="%"
                  outlined
                  required
                  dense
                  class="ma-2 right-align-text"
                  type="number"
                  onclick="this.select()"
              />
              <v-text-field
                  v-model="payable"
                  label="Payable"
                  prepend-inner-icon="mdi-currency-usd"
                  outlined
                  required
                  dense
                  readonly
                  class="ma-2 right-align-text"
              />
            </v-row>
          </template>
          <template v-slot:item.orderDate="{ item }">
            {{ getDate(item.date) }}
          </template>
          <template v-slot:item.email="{ item }">
            <div style="display: flex; align-items: center; gap: 5px;">
              <span>{{ item.email }}</span><img v-if="item.country" style="width: 20px; height: 20px;" :src="getCountryFlag(item.country)" />
            </div>
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{ getDate(item.createdAt) }}
          </template>
          <template v-slot:item.total="{ item }">
            ${{ item.total }}
          </template>
          <template v-slot:item.publishableAmount="{ item }">
            ${{ item.publishableAmount }}
          </template>
          <template v-slot:item.amount="{ item }">
            ${{ item.amount }}
          </template>
          <template v-slot:item.discount="{ item }">
            ${{ item.discount }}
          </template>
          <template v-slot:item.totalAmount="{ item }">
            ${{ item.totalAmount }}
          </template>
          <template v-slot:item.articleWritingPrice="{ item }">
            ${{ item.articleWritingPrice }}
          </template>
          <template v-slot:item.excludeDiscountTotal="{ item }">
            ${{ item.excludeDiscountTotal }}
          </template>
          <template v-slot:item.totalInvoiceAmount="{ item }">
            ${{ item.totalInvoiceAmount }}
          </template>
          <template v-slot:item.totalUnpaidAmount="{ item }">
            ${{ item.totalUnpaidAmount }}
          </template>
          <template v-slot:item.totalPaidAmount="{ item }">
            ${{ item.totalPaidAmount }}
          </template>
          <template v-slot:item.generalPrice="{ item }">
            ${{ item.generalPrice }}
          </template>
          <template v-slot:item.othersPrice="{ item }">
            ${{ item.othersPrice }}
          </template>
          <template v-slot:item.generalAdminPrice="{ item }">
            ${{ item.generalAdminPrice }}
          </template>
          <template v-slot:item.othersAdminPrice="{ item }">
            ${{ item.othersAdminPrice }}
          </template>
          <template v-slot:item.totalDeletedAmount="{ item }">
            ${{ item.totalDeletedAmount }}
          </template>
          <template v-slot:item.totalRefundAmount="{ item }">
            ${{ item.totalRefundAmount }}
          </template>
          <template v-slot:item.invoiceDetails="{ item }">
            <chip-button
                text="View Invoice Details"
                :x-small="false"
                color="green"
                @onClickHandler="invoiceDetailsHandler(item)"
            />
          </template>
          <template v-slot:item.orderDetails="{ item }">
            <chip-button
                text="View Order Details"
                :x-small="false"
                color="green"
                @onClickHandler="orderDetailsHandler(item)"
            />
          </template>
          <template v-slot:item.site="{ item }">
            <v-list-item two-line class="ma-0 pa-1" style="min-height: auto !important;">
              <v-list-item-content class="ma-0 pa-0">
                <v-list-item-title>{{ item.site_url }}</v-list-item-title>
                <v-list-item-subtitle>
                  <chip-button text="New Tab" :item="item.liveLink" @onClickHandler="onNewTabHandler"/>
                  Or
                  <chip-button text="Copy Link" color="green" :item="item.liveLink"
                               @onClickHandler="onCopyLinkHandler"/>
                  Total: ${{ item.total }} USD
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:item.paymentDetails="{ item }">
            <table class="ma-1">
              <tr>
                <td>Payment Method:</td>
                <td>{{ item.methods ? item.methods.name : 'N/A' }}</td>
              </tr>
              <tr v-if="item.payerId">
                <td>Paypal Payer ID:</td>
                <td>{{ item.payerId }}</td>
              </tr>
              <tr v-if="item.paymentId">
                <td>Paypal Payment ID:</td>
                <td>{{ item.paymentId }}</td>
              </tr>
              <tr v-if="item.refno">
                <td>2Checkout:</td>
                <td>{{ item.refno }}</td>
              </tr>
              <tr v-if="item.stripeId">
                <td>Stripe ID:</td>
                <td>{{ item.stripeId }}</td>
              </tr>
              <tr v-if="item.paddleId">
                <td>Checkout ID:</td>
                <td>{{ item.paddleId }}</td>
              </tr>
              <tr v-if="item.paddleOrderId">
                <td>Order ID:</td>
                <td>{{ item.paddleOrderId }}</td>
              </tr>
              <tr v-if="item.trxnId">
                <td>Trxn ID:</td>
                <td>{{ item.trxnId }}</td>
              </tr>
              <tr v-if="item.phone">
                <td>Phone:</td>
                <td>{{ item.phone }}</td>
              </tr>
              <tr v-if="item.paymentDate">
                <td>Payment Date:</td>
                <td>{{ getDateTime(item.paymentDate) }}</td>
              </tr>
            </table>
          </template>
          <template v-slot:item.paymentStatus="{ item }">
            <g-payment-status-chip v-if="!paymentStatus && showComponent.isSummary" text="Showing All"
                                   color="amber darken-4"/>
            <g-payment-status-chip v-else-if="item.isDeleted" text="Deleted" color="blue"/>
            <g-payment-status-chip v-else-if="item.paymentStatus === 100"/>
            <g-payment-status-chip v-else-if="item.paymentStatus === 200" color="green" text="Paid"/>
            <g-payment-status-chip v-else-if="item.paymentStatus === 300" color="lime" text="Pending"/>
            <g-payment-status-chip v-else-if="item.paymentStatus === 400" color="yellow" text="Refund"/>
            <g-payment-status-chip v-else-if="item.paymentStatus === 600" color="orange" text="Republish"/>
          </template>
          <template v-slot:item.isPrepayment="{ item }">
            <g-payment-status-chip v-if="item.isPrepayment" text="Prepaid" color="deep-purple darken-3"/>
          </template>
          <template v-slot:item.totalPaidAmount="{ item }">
            {{ setPaymentSymbol(item.totalPaidAmount) }}
          </template>
          <template v-slot:item.totalDue="{ item }">
            {{ setPaymentSymbol(item.totalDue) }}
          </template>
          <template v-slot:item.totalDeleted="{ item }">
            {{ setPaymentSymbol(item.totalDeleted) }}
          </template>
          <template v-slot:item.paymentFees="{ item }">
            {{ setPaymentSymbol(item.paymentFees) }}
          </template>
          <template v-slot:item.grossTotal="{ item }">
            {{ setPaymentSymbol(item.grossTotal) }}
          </template>
          <template v-slot:item.numsOfDate="{ item }">
            {{ convertNumOfDays(item.numsOfDate) }}
          </template>
          <template v-slot:body.append v-if="showComponent.isStaffPDF">
            <tr :class="{'v-data-table__mobile-table-row':isMobile}">
              <td :colspan="`${isMobile?4:1}`" :class="{'v-data-table__mobile-row':isMobile}"></td>
              <td :colspan="`${isMobile?4:1}`" :class="{'v-data-table__mobile-row':isMobile}" class="text-right">
                Grand Total:
              </td>
              <td :colspan="`${isMobile?4:1}`" :class="{'v-data-table__mobile-row':isMobile}" class="text-center">
                {{ isMobile ? 'Orders: ' : '' }}<strong>{{ totalOrderSum }}</strong></td>
              <td :colspan="`${isMobile?4:1}`" :class="{'v-data-table__mobile-row':isMobile}" class="text-center">
                {{ isMobile ? 'Links: ' : '' }}<strong>{{ totalLinkSum }}</strong></td>
            </tr>
          </template>
          <template v-slot:footer.prepend>
            <v-subheader v-if="user && user.role_id === 1">
              <div v-if="showGrandTotal">Grand Total: &nbsp;<strong>${{ totalAmount }} USD</strong></div> &nbsp;
              <div v-if="showPaymentFooter">
                Grand Total: &nbsp;<strong>${{ grandTotal }} USD</strong>&nbsp;
                Total Fees: &nbsp;<strong>${{ totalFeeAmount }} USD</strong>&nbsp;
                Paddle Gross Total: &nbsp;<strong>${{ totalGrossAmount }} USD</strong>&nbsp;
              </div> &nbsp;
              <div v-if="showComponent.isAdminPrice">Admin Price Total: <strong>${{ totalAdminPrice }} USD &nbsp;</strong></div>
              <div v-if="showComponent.isSite">Publishable Total: <strong>${{ publishableAmount }} USD &nbsp;</strong>Article
                Writing Total: <strong>${{ unpublishableAmount }} USD</strong> Total Discount:
                <strong>${{ totalDiscount }} USD</strong> Total Payable: <strong>${{ totalPayable }} USD</strong>
              </div>
            </v-subheader>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <keep-alive>
      <component
          v-bind:is="currentComponent"
          ref="component"
          :item="currentItem"
          :show="showPopup"
          @closePopupHandler="closePopupHandler"
      />
    </keep-alive>
  </g-card>
</template>

<script>
import PageProgress from "./PageProgress";
import PageTitle from "./PageTitle";
import {mapState} from "vuex";
import GFilterAutocomplete from "./GFilterAutocomplete";
import DateHelper from "./mixins/DateHelper";
import GPaymentStatusChip from "./GPaymentStatusChip";
import GPAutoComplete from "./GPAutoComplete";
import ChipButton from "./buttons/ChipButton";
import axios from "axios";
import GOverlay from "./GOverlay";
import moment from "moment/moment";
import GCard from "./GCard";
import ReportCounterLinkPopup from "./popups/Reports/CounterLinkPopup"
import LinkPopup from "./popups/LinkPopup";
import GDateSelector2 from "./calendar/GDateSelector2";
import {countries} from "country-list-json";
import countryFlag from "../json/country-by-flag.json";
import _ from "lodash";
import Sites from "../services/Sites";
export default {
  name: "ReportListPage",
  components: {
    GDateSelector2,
    GCard,
    GOverlay,
    ChipButton,
    GPAutoComplete,
    GPaymentStatusChip,
    GFilterAutocomplete,
    PageTitle,
    PageProgress,
    ReportCounterLinkPopup
  },
  mixins: [DateHelper],
  props: {
    pageTitle: {
      type: String,
      default: function () {
        return ''
      }
    },
    pageIcon: {
      type: String,
      default: function () {
        return ''
      }
    },
    headers: {
      type: Array,
      default: function () {
        return [];
      }
    },
    stateEndPoint: {
      type: String,
      default: function () {
        return "";
      }
    },
    descending: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    sort: {
      type: String,
      default: function () {
        return 'orderId';
      }
    },
    actions: {
      type: Object,
      default: function () {
        return {};
      }
    },
    statusArr: {
      type: Array,
      default: function () {
        return [
          {name: 'Unpaid', id: 100},
          {name: 'Pending', id: 300},
          {name: 'Republish', id: 600},
          {name: 'Prepaid', id: 700},
          {name: 'Paid', id: 200},
          {name: 'Deleted', id: 500}
        ]
      }
    },
    showComponent: {
      type: Object,
      default: function () {
        return {
          isUser: true,
          isStatus: true,
          isMethod: false,
          isEmail: true,
          isSite: false,
          isSummary: false,
          isDate: true,
          isBulk: false,
          isAdminPrice: false,
        }
      }
    },
    itemKey: {
      type: String,
      default: function () {
        return 'id';
      }
    },
    showGrandTotal: {
      type: Boolean,
      default: () => true
    },
    showStatement: {
      type: Boolean,
      default: () => true
    },
    showPaymentFooter: {
      type: Boolean,
      default: () => false
    },
    isRandomPayment: {
      type: Boolean,
      default: () => false
    },

  },
  watch: {
    pagination: {
      handler() {
        this.loadData();
      },
      deep: true
    }
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      search:null,
      sites: [],
      siteIds: [],
      isRelay: false,
      limitList: [5, 10, 20, 50, 100],
      pagination: {
        itemsPerPage: 20
      },
      total: 0,
      totalAmount: 0,
      totalLinkSum: 0,
      totalOrderSum: 0,
      publishableAmount: 0,
      totalAdminPrice: 0,
      unpublishableAmount: 0,
      totalDiscount: 0,
      totalPayable: 0,
      prOfPayable: 0,
      grandTotal: 0,
      totalFeeAmount: 0,
      totalGrossAmount: 0,
      /*startDate: moment().subtract(2, 'month').startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD'),*/
      startDate: moment().startOf('days').format('YYYY-MM-DD'),
      endDate: moment().endOf('days').format('YYYY-MM-DD'),
      searchUser: '',
      paymentStatus: '',
      payMethod: '',
      searchEmail: '',
      searchSite: '',
      siteOwner: '',
      param1: null,
      backButton: {
        text: "Back to Reports",
        icon: "mdi-keyboard-backspace",
        name: "Reports",
        path: "/reports",
      },
      showPopup: false,
      currentItem: {},
      currentComponent: null,
      clientTypes: [
        {name: "General Clients", id: -1},
        {name: "Fixed Clients", id: 2},
        {name: "Special Clients", id: 3},
        {name: "More Special Clients", id: 4},
        {name: "Agency Clients", id: 5},
        {name: "Buyers", id: 5},
      ],
      organizations: [
        {
          value: "webexpressmedia",
          text: "Web Express Media"
        },
        {
          value: "guestpostinglink",
          text: "Guest Posting Link"
        },
        {
          value: "extremebacklink",
          text: "Extreme Backlink"
        }
      ]
    }
  },
  computed: {
    ...mapState({
      data: function (state) {
        const stateData = this.stateEndPoint.split('.').reduce((p, c) => p && p[c] || null, state);

        this.total = stateData.total;
        this.totalLinkSum = stateData.totalLinkSum ? stateData.totalLinkSum : 0;
        this.totalOrderSum = stateData.totalOrderSum ? stateData.totalOrderSum : 0;
        this.totalAmount = stateData.totalAmount ? stateData.totalAmount : 0.00;
        this.totalAmount = stateData.totalAmount ? stateData.totalAmount : 0.00;
        this.totalAmount = stateData.totalAmount ? stateData.totalAmount : 0.00;
        this.publishableAmount = stateData.publishableAmount ? stateData.publishableAmount : 0.00;
        this.unpublishableAmount = stateData.unpublishableAmount ? stateData.unpublishableAmount : 0.00;
        this.totalDiscount = stateData.totalDiscount ? stateData.totalDiscount : 0.00;
        this.grandTotal = stateData.grandTotal ? stateData.grandTotal : 0.00;
        this.totalFeeAmount = stateData.totalFeeAmount ? stateData.totalFeeAmount : 0.00;
        this.totalPayable = stateData.totalPayable ? stateData.totalPayable : 0.00;
        this.totalGrossAmount = stateData.totalGrossAmount ? stateData.totalGrossAmount : 0.00;
        this.totalAdminPrice = stateData.totalAdminPrice ? stateData.totalAdminPrice : 0.00;

        return stateData.data || stateData.items;
      },
      user: state => state.auth.user,
      isMobile() {
        return this.$vuetify.breakpoint.name === 'xs'
      }
    }),
    payable() {
      const pr = parseFloat(this.prOfPayable / 100) === 0 ? 1 : parseFloat(this.prOfPayable / 100);
      return parseFloat(this.totalPayable * pr).toFixed(2);
    },
  },
  methods: {
    loadData(resetPaging = false) {
      let pagingData = this.getPagingData();
      if (resetPaging) {
        pagingData.page = 1;
      }

      if (this.isRelay || !this.actions.load) return;
      this.showLoading();

      pagingData.siteIds = this.siteIds.join(",");

      this.$store.dispatch(this.actions.load, {...pagingData})
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.hideLoading()))
    },
    showLoading() {
      this.isRelay = true;
    },
    hideLoading() {
      this.isRelay = false;
    },
    getPagingData() {
      return {
        ...this.pagination,
        rowsPerPage: this.pagination.itemsPerPage,
        sortBy: this.pagination.sortBy[0],
        descending: this.pagination.sortDesc[0],
        startDate: this.startDate,
        endDate: this.endDate,
        user: this.searchUser,
        paymentStatus: this.paymentStatus,
        email: this.searchEmail,
        payMethod: this.payMethod,
        siteId: this.searchSite,
        siteOwner: this.siteOwner,
        param1: this.param1,
      };
    },
    onChangeDateHandler(range) {
      const {startDate, endDate} = range;
      this.startDate = startDate;
      this.endDate = endDate;
      this.loadData(true);
    },
    onChangeFilterHandler(selection, handler) {
      if (!selection) {
        this[handler] = '';
      }
      this[handler] = selection;
      this.loadData(true);
    },
    handleClearSearchResult() {
      this.searchEmail = '';
      this.loadData(true);
    },
    onSelections(selection) {
      this.searchEmail = selection.email;
      this.loadData(true);
    },
    handleClearSiteSearchResult() {
      this.searchSite = '';
      this.loadData(true);
    },
    onSelectionsSite(selection) {
      this.searchSite = selection.id;
      this.loadData(true);
    },
    handleClearSiteOwnerSearchResult() {
      this.siteOwner = '';
      this.loadData(true);
    },
    onSelectionsSiteOwner(selection) {
      this.siteOwner = selection.id;
      this.loadData(true);
    },
    onNewTabHandler(liveLink) {
      window.open(liveLink, '_blank');
    },
    onCopyLinkHandler(liveLink) {
      const me = this;
      this.$copyText(liveLink).then(function (e) {
        me.$store.commit('SET_SNACKBAR', {text: 'Clipboard Copied!', type: 'info'})
      }, function (e) {
        me.$store.commit('SET_SNACKBAR', {text: 'Can not Copied!', type: 'error'})
      })
    },
    convertNumOfDays(value) {
      let numOfDays = Number(value);
      let years = Math.floor(numOfDays / 365);
      let months = Math.floor(numOfDays % 365 / 30);
      let days = Math.floor(numOfDays % 365 % 30);

      let yearsDisplay = years > 0 ? years + (years == 1 ? " year " : " years ") : "";
      let monthsDisplay = months > 0 ? months + (months == 1 ? " month " : " months ") : "";
      let daysDisplay = days > 0 ? days + (days == 1 ? " day" : " days") : "";

      if (!(yearsDisplay || monthsDisplay || daysDisplay)) {
        return 'today'
      }

      return yearsDisplay + monthsDisplay + daysDisplay + ' ago';
    },
    setPaymentSymbol(amount) {
      return '$' + amount;
    },
    downloadStatement() {
      if (!(this.startDate || this.endDate)) return;
      this.isRelay = true;
      let api = process.env.VUE_APP_API_BASE_URL;
      let requestPath = `${api}${this.actions.pdfURL}?startDate=${this.startDate}&endDate=${this.endDate}&param1=${this.param1}`;
      const token = this.$store.state.auth.token;
      axios({
        method: 'get',
        url: requestPath,
        responseType: 'blob',
        headers: {Authorization: `Bearer ${token}`}
      })
          .then((response) => {
            let pdfName = `${this.actions.nameOfPDF}-from-${this.startDate}-to-${this.endDate}.pdf`;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', pdfName);
            document.body.appendChild(link);
            link.click();
            this.isRelay = false;
          })
          .catch((errors) => {
            this.isRelay = false;
          })
    },
    downloadStaff() {
      if (!(this.startDate || this.endDate)) return;
      this.isRelay = true;
      let api = process.env.VUE_APP_API_BASE_URL;
      let requestPath = api + '/download-staff?startDate=' + this.startDate + '&endDate=' + this.endDate;
      const token = this.$store.state.auth.token;
      axios({
        method: 'get',
        url: requestPath,
        responseType: 'blob',
        headers: {Authorization: `Bearer ${token}`}
      })
          .then((response) => {
            let pdfName = `RabbiITFirm-Staff-Report-of-${this.startDate}-to-${this.endDate}.pdf`;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', pdfName);
            document.body.appendChild(link);
            link.click();
            this.isRelay = false;
          })
          .catch((errors) => {
            this.isRelay = false;
          })
    },
    downloadSalesSites() {
      if (!(this.startDate || this.endDate)) return;
      const payload = this.getPagingData();
      const endpoint = payload ? `${ Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;
      this.isRelay = true;
      let api = process.env.VUE_APP_API_BASE_URL;
      let requestPath = api + '/sales-counter/download-sales-report-by-site?' + endpoint;
      const token = this.$store.state.auth.token;
      axios({
        method: 'get',
        url: requestPath,
        responseType: 'blob',
        headers: {Authorization: `Bearer ${token}`}
      })
          .then((response) => {
            let pdfName = `RabbiITFirm-Sales-Site-List-${this.startDate}-to-${this.endDate}.pdf`;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', pdfName);
            document.body.appendChild(link);
            link.click();
            this.isRelay = false;
          })
          .catch((errors) => {
            this.isRelay = false;
          })
    },
    methodHandler(selection) {
      if (selection) {
        this.payMethod = selection.id;
      } else {
        this.payMethod = "";
      }

      this.loadData(true);
    },
    userHandler(selection) {
      if (selection) {
        this.searchUser = selection.id;
      } else {
        this.searchUser = "";
      }

      this.loadData(true);
    },
    orderDetailsHandler(item) {
      this.currentComponent = ReportCounterLinkPopup;
      this.showPopup = true;
      this.currentItem = item;
    },
    invoiceDetailsHandler(item) {
      this.currentComponent = LinkPopup;
      this.showPopup = true;
      this.currentItem = item;
    },
    closePopupHandler() {
      this.showPopup = false;
    },
    getCountryFlag(code){
      const country = countries.find((item) => item.code === code);
      if (!country) return '';

      let flag = countryFlag.find((item) => item.country === country.name);
      if (flag) {
        return flag.flag_base64;
      }
      return '';
    },
    onSelectionsBulkSites(selections) {
      debugger
      this.siteIds = selections;
      this.loadData(true);
    },
   async siteLinksHandler(event) {
      if(!event) return;
      this.isRelay = true;
      try {
        let links = event.split("\n");
        links = _.filter(links);
        let sites = _.uniq(links);
        if (sites.length > 50) {
          this.$store.commit("SET_SNACKBAR", {message: "Maximum Allowed 50 Sites"})
          return;
        }
        const {data: {data: {items}}} = await Sites.getBulkSite({sites: sites});
        this.siteIds = items.map((item) => item.id);
        this.sites = items;
        this.isRelay = false;
        this.loadData(true);
      } catch (error) {
        this.isRelay = false;
      }
    }
  }
}
</script>

<style scoped>
</style>